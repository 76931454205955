// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-assetmanagement-mdx": () => import("./../../../../src/assetmanagement.mdx" /* webpackChunkName: "component---src-assetmanagement-mdx" */),
  "component---src-ayatjurnal-mdx": () => import("./../../../../src/ayatjurnal.mdx" /* webpackChunkName: "component---src-ayatjurnal-mdx" */),
  "component---src-bankorcash-mdx": () => import("./../../../../src/bankorcash.mdx" /* webpackChunkName: "component---src-bankorcash-mdx" */),
  "component---src-driverapps-mdx": () => import("./../../../../src/driverapps.mdx" /* webpackChunkName: "component---src-driverapps-mdx" */),
  "component---src-index-mdx": () => import("./../../../../src/index.mdx" /* webpackChunkName: "component---src-index-mdx" */),
  "component---src-invoice-mdx": () => import("./../../../../src/invoice.mdx" /* webpackChunkName: "component---src-invoice-mdx" */),
  "component---src-masterdata-mdx": () => import("./../../../../src/masterdata.mdx" /* webpackChunkName: "component---src-masterdata-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-refund-mdx": () => import("./../../../../src/refund.mdx" /* webpackChunkName: "component---src-refund-mdx" */),
  "component---src-salesorder-mdx": () => import("./../../../../src/salesorder.mdx" /* webpackChunkName: "component---src-salesorder-mdx" */),
  "component---src-vendorbill-mdx": () => import("./../../../../src/vendorbill.mdx" /* webpackChunkName: "component---src-vendorbill-mdx" */)
}

